import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import { Redirect } from 'react-router';
import axios from 'axios';
import * as base from "../global";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//https://reactjsexample.com/a-lightweight-production-ready-carousel-for-react/

const responsive = {

    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 5000, min: 4000 },
        items: 6
    },
    desktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 6
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};



export class Bestandlatest extends Component {
    constructor() {
        super();
        let loggdin = false;
        this.state = {
            allsubject: []
        }
    }
    componentDidMount() {
        axios.get(base.BASE_URL + '/bestofalllist').then(res => {
            this.setState({ allsubject: res.data });
        });
    }


    render() {
        /*     if (this.state.loggdin) {
              return <Redirect to="/Dashboard" />
            } */
        return (
            /*     <div style={{ backgroundImage: `url(${background})` }}> profile-cover main-banner.jpg*/
            <div>
                <div className="favorites-contens">

                <Carousel centerMode={true}  responsive={responsive}>
                         {this.state.allsubject.map((result) => {
                            return (
 
                                <div className="slide-item" style={{ marginRight: 10 }}>
                                <div className="block-images position-relative">
                                  <div className="img-box">
                                  {/*   <img src="images/upcoming/05.jpg" className="img-fluid" alt /> */}
                                  <Link to={`/moviedetails/${result.movieid}/${result.categoryid}/${result.moviename }`} className='bg-image hover-zoom'>
                                        <img src={`${base.IMAGE_URL}/${result.image}`} className="img-fluid img-zoom" 
                                          alt={result.moviename} style={{ height: '100%', width: '100%' }} />
                                      </Link>
                                  </div>
                                  <div className="block-description">
                                 {/*    <h6 className="iq-title"><a href="show-details.html">{result.moviename}</a></h6>
                                    <div className="hover-buttons">
                                       <Link to={`/categorydetails/${result.movieid}/${result.moviename}`} role="button" className="btn btn-hover">
                                        <i className="fa fa-play mr-1" aria-hidden="true" />
                                        Start Learning
                                      </Link>
                                    </div> */}
                                  </div>

                                </div>
                              </div>
                               
                         )
                        })} 
                        </Carousel>

                </div>
            </div>
        )
    }
}

export default Bestandlatest