import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import * as base from "./global";
import { Redirect } from 'react-router';
import axios from 'axios';
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Sliderpage from './slider/sliderpage'
import Dramaseries from './drama/dramaseries'
import Movielist from './drama/movielist'
import Episodelist from './drama/Episodelist'
import Musiclist from './drama/musiclist'
import Bestandlatest from './drama/Bestandlatest'

export class Home extends Component {
  constructor() {
    super();
    let loggdin = false;
   // this.bannerone = this.bannerone.bind(this);
    this.state = {
      email: '',
      Password: '',
      isLoading: false,
      fields: {},
      errors: {},
      datauserprofile: [],
      image: 'maleavter.png',
     // gettracking: props.match.params.id
    }
  }
  componentDidMount() {
    // alert(this.state.email);
    /*     axios.get(base.BASE_URL + '/frontuserfive').then(res => {
          this.setState({ datauserprofile: res.data });
        }); */

	const formdata = {
      pagetype: "Homepage",
      gettracking: ''
      }
      axios.post(base.BASE_URL + "/visitor", formdata, {
      }).then(res => {
      console.log('data..file..' + res.data);
      })

  }
/*   bannerone(){
    window.open("https://www.thechinesewriter.com");
  } */
  render() {
    /*     if (this.state.loggdin) {
          return <Redirect to="/Dashboard" />
        } */
    return (
      /*     <div style={{ backgroundImage: `url(${background})` }}> profile-cover main-banner.jpg*/


      <div>
        <Header />

        <Sliderpage />



        <div className="main-content">
          <section id="iq-favorites" style={{ marginTop: 30 }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 overflow-hidden" style={{ display: 'flex' }}>
                  <div className="col-md-6">
                  <a href="https://viper.com.bd/" target="_blank">
                  <img src="images/movie-banner/add1.jpg" className="w-100" style={{ height: '100%' }} alt className='bg-image hover-zoom'/>
                  </a>

{/*                   <Link onClick={this.bannerone}>
                  <img src="images/movie-banner/add1.jpg" className="w-100" style={{ height: '100%' }} alt className='bg-image hover-zoom'/>
                  </Link> */}
                  </div>
                  <div className="col-md-6">
                  <a href="https://www.hostinger.com/" target="_blank">
                  <img src="images/movie-banner/add2.jpg" className="w-100" style={{ height: '100%' }} alt className='bg-image hover-zoom'/>
                  </a>
                  </div>
                </div>
              </div>
            </div>
          </section>



          <section id="iq-favorites" style={{ marginTop: 30 }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 overflow-hidden">
                  <div className="iq-main-header d-flex align-items-center justify-content-between">
                    <h4 className="main-title">BEST AND LATEST</h4>
                    <Link to="/Viewmore/17" className="main-title">View More</Link>
                  </div>

                  {/* Drama Series  Girls Squad*/}
                  <Bestandlatest />

                  {/* End Drama Series */}
                </div>
              </div>
            </div>
          </section>


          <section id="iq-favorites" style={{ marginTop: 30 }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 overflow-hidden">
                  <div className="iq-main-header d-flex align-items-center justify-content-between">
                    <h4 className="main-title">DRAMA</h4>
                    <Link to="/Viewmore/3" className="main-title">View More</Link>
                  </div>

                  {/* Drama Series  Girls Squad*/}
                  <Dramaseries />

                  {/* End Drama Series */}
                </div>
              </div>
            </div>
          </section>

          <section id="iq-favorites" style={{ marginTop: 30 }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 overflow-hidden">
                  <div className="iq-main-header d-flex align-items-center justify-content-between">
                    <h4 className="main-title">MUSIC</h4>
                    <Link to="/Viewmore/4" className="main-title">View More</Link>
                  </div>
                  <Musiclist />
                </div>
              </div>
            </div>
          </section>




    <section id="iq-favorites" style={{ marginTop: 30 }}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 overflow-hidden">
          <div className="iq-main-header d-flex align-items-center justify-content-between">
            <h4 className="main-title">WEB SERIES</h4>
            <Link to="/Viewmore/14" className="main-title">View More</Link>
          </div>
              <Episodelist />
        </div>
      </div>
    </div>
  </section> 

          {/*   <section id="iq-favorites" style={{ marginTop: 19 }}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12 overflow-hidden">
          <div className="iq-main-header d-flex align-items-center justify-content-between">
            <h4 className="main-title">MOVIE</h4>
            <Link to="/Movies" className="main-title">View More</Link>
          </div>
              <Movielist />
        </div>
      </div>
    </div>
  </section> */}


          {/* Music */}




        </div>

        <Footer />
      </div>
    )
  }
}

export default Home