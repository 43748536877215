//import React, { Component } from 'react';
import React, { useEffect, useState } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
//pagination npm install react-js-pagination
import { Button, Modal } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import ReactDOM from 'react-dom';
import Categoriesview from './Categoriesview'

//https://www.codecheef.org/article/laravel-react-js-pagination-example-from-scratch
//export default class findpeople extends Component {
function Findmovie(props) {
  const [state, setData] = useState({
    users: ''
  });
  const [txtsearch, setTextsearch] = useState(props.match.params.id);


  const fetchData = async (pageNumber = 1) => {
    //http://192.168.100.8/OTTPlatform/laravel_api/public/api/allmovielist/candy?page=1
    const api = await fetch(base.BASE_URL + `/allmovielist/${txtsearch}?page=${pageNumber}`);
    setData({
      users: await api.json()
    });
  };

  useEffect(() => {
    alert(txtsearch);
    fetchData();
  }, [])


  return (
    <div>
      <Header />
      <div className="main-content" style={{ marginTop: 0 }}>
        <section id="iq-favorites" style={{ marginTop: 2 }}>
          <div className="container-fluid">


            <div className="row">
              <div className="col-sm-12 overflow-hidden" style={{ display: 'flex' }}>
                <div className="col-md-12">
                  <div style={{ marginTop: 95 }}>
                    <h3>Result : {txtsearch} </h3>



                    {/* Start Section */}
                    <div className="main-content">


                      <section className="container-fluid seasons">
                        <div className="iq-custom-select d-inline-block sea-epi s-margin">
                          {/*       <select name="cars" className="form-control season-select">
        <option value="season1">Season 1</option>
        <option value="season2">Season 2</option>
        <option value="season3">Season 3</option>
      </select> */}

                          <div action="#" className="searchbox___">
                            <div className="form-group position-relative" style={{ display: 'flex' }}>
                              <input type="text" name="txtsearch" id="txtsearch" value={txtsearch} placeholder="search by name"
                                className="text search-input font-size-12" />
                              <Button type="button" className="bg-blue-600 font-semibold rounded-md text-center text-white w-full" style={{ marginTop: 0 }}>
                                Search
                              </Button>

                            </div>
                          </div>

                        </div>




                        {/*                         <ul className="trending-pills d-flex nav nav-pills align-items-center text-center s-margin" role="tablist">
                          <li className="nav-item">
                            <a className="nav-link active show" data-toggle="pill" href="#episodes" role="tab" aria-selected="true">Episodes</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" data-toggle="pill" href="#popularclips" role="tab" aria-selected="false">Popular
                              Clips</a>
                          </li>
                        </ul> */}
                        <div className="tab-content">
                          <div id="episodes" className="tab-pane fade active show" role="tabpanel">
                            <div className="block-space">

                              <div className="row">
                                <div className="col-md-12">
                                  <Categoriesview />
                                </div>

                              </div>


                              <div className="row">



                                {
                                  state?.users?.data ?
                                    state?.users?.data?.map((result) => (

                                      <div className="col-1-5 col-md-6 iq-mb-30">
                                        <div className="epi-box">
                                          <div className="epi-img position-relative">
                                            <Link to={`/moviedetails/${result.movieid}/${result.categoryid}/${result.moviename}`}>
                                              <img src={`${base.IMAGE_URL}/${result.image}`} className="img-fluid img-zoom" style={{ width: '100%', height: '100%' }} />
                                            </Link>
                                            {/* <div className="episode-number">4</div> */}
                                            <div className="episode-play-info">
                                              <div className="episode-play">
                                                <Link to={`/moviedetails/${result.movieid}/${result.categoryid}/${result.moviename}`}>
                                                  <i className="ri-play-fill" /></Link></div>
                                            </div>
                                          </div>
                                          <div className="epi-desc p-3">
                                            <div className="d-flex align-items-center justify-content-between">
                                              <span className="text-white">{result.moviename}</span>
                                              <span className="text-primary">{result.movieduration}</span>
                                            </div>
                                            <Link to={`/moviedetails/${result.movieid}/${result.categoryid}/${result.moviename}`}>
                                              <h6 className="epi-name text-white mb-0">{result.movie_description}
                                              </h6>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    )) : "Loading..."
                                }





                              </div>
                            </div>
                          </div>
                          <div id="popularclips" className="tab-pane fade" role="tabpanel">
                            <div className="block-space">
                              <div className="row">
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>

                    {/* End Section  */}

                    <div style={{ width: '100%', marginTop: 20 }}>
                      <Pagination
                        activePage={state?.users?.current_page ? state?.users?.current_page : 0}
                        itemsCountPerPage={state?.users?.per_page ? state?.users?.per_page : 0}
                        totalItemsCount={state?.users?.total ? state?.users?.total : 0}
                        onChange={(pageNumber) => {
                          fetchData(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                      //firstPageText="First Page"
                      //lastPageText="Last Lage"
                      />

                    </div>




                  </div>
                </div>

                {/* <div className="col-md-4">
  <h3>Recommand Video</h3>
</div>
 */}




              </div>
            </div>
          </div>
        </section>

      </div>

    </div>
  );
}
export default Findmovie;
