import React from 'react';
import ReactJWPlayer from 'react-jw-player';

const Test = () => {

  const onReady = () => {
    const script = document.createElement("script");

    script.src =
      "//edge-player.wirewax.com/ww4release/javascripts/wirewax-jwplayer.js";
    script.async = true;

    document.body.appendChild(script);
  };

  // wirewax v4 plugin doesn't support autoPlay for the most recent jwplayer major version (8.X)
  // a way to walk around is to do a checking after ReactJWPlayer autoStarted:
  // Ref: https://github.com/micnews/react-jw-player#optional-player-event-hook-props
  const handleAutoStart = (event) => {
    const checkingAutoStart = setInterval(() => {
      if (!window.wirewax.playerReady) return;

      window.wirewax.playerApi.playVideo();
      clearInterval(checkingAutoStart);
    }, 100);
  }

  // only run once on mobile
  const onPlayHandler = (event) => {
    handleAutoStart();
  }

  // only run once on desktop
  const onAutoStartHandler = (event) => {
    handleAutoStart();
  }

  return (
    <div
      className="container-wrapper"
      data-mediaid="TAITbudl"
      style={{ height: '100%', width: '100%' }}
    >
      <ReactJWPlayer
          playerId="video-container"  // WX player plugin is using this id to inject components to the correct position
          playerScript="https://content.jwplatform.com/libraries/j9BLvpMc.js"
          playlist="https://cdn.jwplayer.com/v2/media/TAITbudl"
          
          className="jw-player-wrapper"
          onReady={onReady}
          onPlay={onPlayHandler} // for autoStart
          onAutoStart={onAutoStartHandler} // for autoStart
          isAutoPlay={true} // for autoStart
      />
    </div>
  );
};

export default Test;
