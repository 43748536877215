import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
//import useForm from 'react-hook-form'
//import { Button, Form } from 'reactstrap';
import * as base from "./global";
import axios from 'axios';
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import GoogleLogin from 'react-google-login';
//https://stackoverflow.com/questions/41296668/reactjs-form-input-validation

export class Unsubscribe extends Component {

  constructor(props) {
    super(props);
    let loggdin = false;
    this.state = {
      isLoading: 'notloading',
      logincheck:'',
      subscriberid:props.match.params.id
    };

  }
  componentDidMount() {
  fetch(base.BASE_URL + '/unsubscribe', {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        subscriberid: this.state.subscriberid
    })
  }).then((Response) => Response.json())
    .then((Result) => {
      //   alert(Result.message);\
      if (Result.message == 'success') {
        this.setState({ loggdin: 'start', loaderfile: 'notloading' })

      }
      else {
      }

    })
  }
  render() {


    return (
      <div>
        <Header />

        <section className="sign-in-page">
          <div className="container">
            <div className="row justify-content-center align-items-center height-self-center">
              <div className="col-lg-7 col-md-12 align-self-center">
                <div className="sign-user_card ">

                  <div className="mt-3">
                    <div className="d-flex justify-content-center links">
                      Thank you for Unsubscribe the email notification.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <Footer />
      </ div>
    )

  }
}

export default Unsubscribe