import React, { useEffect, useState } from 'react';

import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
//import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//npm i --save-dev @types/react-jw-player
//https://www.npmjs.com/package/react-jw-player
//https://www.npmjs.com/package/react-jw-player
import ReactJWPlayer from 'react-jw-player';


function Movieplay(props) {
    const [moviedetails, setMoviedetails] = useState([]);
    const [categoriesdetails, setCategoriesdetails] = useState([]);
    const [rels, setRels] = useState([]);
    const [categoryid, setCategoryid] = useState(props.match.params.id);
    const [categoryids, setCategoryids] = useState(props.match.params.categoryid);
    const [videourl, setVideourl] = useState('https://cdn.jwplayer.com/v2/playlists/');

    useEffect(() => {

        const getCategories = async () => {
            try {
                const res = await axios.get(base.BASE_URL + "/singlemovie/" + categoryid);
                setCategoriesdetails(res.data);
                console.log('movie.....' + res.data);
            } catch (err) {
                console.log(err);
            }
        };
        getCategories();
      //  insertwatch();
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <div>
  {categoriesdetails.map((movie) => (
       <div>
    {/*  https://codesandbox.io/s/z3mqq2wx2l?file=/src/index.js:378-449 */}
        <ReactJWPlayer
            playerId="jw-player"
            // playerId="oW4ryqHc"
            playerScript="https://content.jwplatform.com/libraries/jvJ1Gu3c.js"
            playlist={movie.apiid}
            //uzge3hFE working from office account 0ZlQ34C6
            onDisplayClick={() => (document.body.style.backgroundColor = "black")}
            
        />
         </div>
        ))}                               

        </div>
    );

}
export default Movieplay;