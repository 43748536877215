import React, { useEffect, useState } from 'react';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";

import axios from 'axios';
function Homepage(props) {

    const [categoryid, setCategoryid] = useState(props.match.params.id);


    useEffect(() => {

          // alert(categoryid)
            const formdata = {
                pagetype: "Homepage",
                gettracking: categoryid
                }
                axios.post(base.BASE_URL + "/visitor", formdata, {
                }).then(res => {
                console.log('data..file..' + res.data);
                })

    }, []);



    if (categoryid == '1') {
        return <Redirect to="/" />
    }
    return (


        <div>
            <section id="iq-favorites" >
                <div className="container-fluid">
                    <div className="block-space">
                        <div className="row" style={{ marginTop: 40 }}>
                            <div className="col-md-8">
                                    <h3>Redirection.........</h3>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    );

}
export default Homepage;