import React, { useEffect, useState } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
//import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Sliderpage from './slider/sliderpage'
import Footer from '../Layout/Footer';


import Dramaseries from './drama/dramaseries'
import Movielist from './drama/movielist'
import Episodelist from './drama/Episodelist'
import Musiclist from './drama/musiclist'


export default function Dashboard(props) {
  const [moviedetails, setMoviedetails] = useState([]);
  const [emailaddress, setEmailaddress] = useState(localStorage.getItem("emailaddress"));


  useEffect(() => {
    // alert(userid);
   // alert(emailaddress)
   if(emailaddress== null)
   {
    setEmailaddress('Yes')

   }
    const getConversations = async () => {
      try {
        const res = await axios.get(base.BASE_URL + "/allvideo.php");
        setMoviedetails(res.data);
        //console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getConversations();
  }, []);



  return (
    <div>
      <Header />

      <Sliderpage/>
      <div className="main-content" style={{ marginTop: 0 }}>


<section id="iq-favorites">
  <div className="container-fluid">
    <div className="row">
      <div className="col-sm-12 overflow-hidden">
        <div className="iq-main-header d-flex align-items-center justify-content-between">
          <h4 className="main-title">EPISODE</h4>
          <Link to="/Drama" className="main-title">View More</Link>
        </div>

         {/* Episode*/}
            <Episodelist />

        {/* End Drama Series */}
      </div>
    </div>
  </div>
</section>

<section id="iq-favorites" style={{ marginTop: 19 }}>
  <div className="container-fluid">
    <div className="row">
      <div className="col-sm-12 overflow-hidden">
        <div className="iq-main-header d-flex align-items-center justify-content-between">
          <h4 className="main-title">MOVIE</h4>
          <Link to="/Movies" className="main-title">View More</Link>
        </div>

         {/* Drama Series  Girls Squad*/}
            <Movielist />

        {/* End Drama Series */}
      </div>
    </div>
  </div>
</section>

<section id="iq-favorites" style={{ marginTop: 19 }}>
  <div className="container-fluid">
    <div className="row">
      <div className="col-sm-12 overflow-hidden">
        <div className="iq-main-header d-flex align-items-center justify-content-between">
          <h4 className="main-title">DRAMA</h4>
          <Link to="/Drama" className="main-title">View More</Link>
        </div>

         {/* Drama Series  Girls Squad*/}
            <Dramaseries />

        {/* End Drama Series */}
      </div>
    </div>
  </div>
</section>
{/* Music */}
<section id="iq-favorites" style={{ marginTop: 19 }}>
  <div className="container-fluid">
    <div className="row">
      <div className="col-sm-12 overflow-hidden">
        <div className="iq-main-header d-flex align-items-center justify-content-between">
          <h4 className="main-title">MUSIC</h4>
          <a href="show-single.html" className="main-title">View More</a>
        </div>

         {/* Drama Series  Girls Squad*/}
            <Musiclist />

        {/* End Drama Series */}
      </div>
    </div>
  </div>
</section>



</div>     
<Footer/>
    </div>
  );

}
