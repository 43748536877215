import React, { Component } from 'react'


export const BASE_URL = "https://hussklive.com/api/public/api";
export const IMAGE_URL = "https://hussklive.com/cms/assets/uploads";    
 


/* export const BASE_URL = "http://192.168.100.8/OTTPlatform/laravel_api/public/api";
export const IMAGE_URL = "http://192.168.100.8/OTTPlatform/cms/assets/uploads";     */
