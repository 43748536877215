import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import { Redirect } from 'react-router';
import axios from 'axios';
import * as base from "./global";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//https://reactjsexample.com/a-lightweight-production-ready-carousel-for-react/

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};



export class Categoriesview extends Component {
    constructor() {
        super();
        this.state = {
            allcategory: []
        }
    }
    componentDidMount() {
        axios.get(base.BASE_URL + '/categorieslist').then(res => {
            this.setState({ allcategory: res.data });
        });
    }


    render() {
        /*     if (this.state.loggdin) {
              return <Redirect to="/Dashboard" />
            } */
        return (
            /*     <div style={{ backgroundImage: `url(${background})` }}> profile-cover main-banner.jpg*/
            <div style={{ marginBottom: 20 }}>
               {/*  <div className="favorites-contens"> */}
                         {this.state.allcategory.map((result) => {
                            return (
 
                                       <Link to={`/moviedetails/${result.categoryid}/${result.categoryname}`} style={{ borderRadius: 15, backgroundColor: '#e8e8e8', color: '#141414', padding: 10, marginRight: 10 }}>
                                        {result.categoryname}
                                      </Link>
                               
                         )
                        })} 

             {/*    </div> */}
            </div>
        )
    }
}

export default Categoriesview