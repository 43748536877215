import React, { Component } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
//import { Link } from 'react-router-dom';
import axios from 'axios';
//import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';


export default class Privacypolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }


  }


  componentDidMount() {

  }

  
  render() {
  

    return (
      <div >
        <Header />
        <div className="main-content" style={{ marginTop: 0 }}>
          <section id="iq-favorites" style={{ marginTop: 30 }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 overflow-hidden" style={{ display: 'flex' }}>
                  <div className="col-md-12">
                    <div style={{ marginTop: 95 }}>
                   <h3> Terms of Use</h3>
<p>Welcome to our website located at http://www.hussklive.com (this &quot;Site&quot;)! This Site is operated by Jibondhara Limited, We prepared this Terms of Use Agreement (this &quot;Agreement&quot;) to help explain the terms that apply to your use of this Site and Services. Provisions in these terms that apply to the Site shall also apply to the Services, and provisions in these terms that apply to the Services shall also apply to this Site. In order to use the interactive features on this Site, you must first register with us through our on-line registration process on this Site. Regardless of how you decide to use this Site, your conduct on this Site and use of the Services is governed by this Agreement. YOU ACCEPT THIS AGREEMENT BY USING THIS SITE AND/OR THE SERVICES IN ANY MANNER. IF YOU DO NOT AGREE TO ALL THESE TERMS THEN DO NOT USE THIS WEBSITE. 
<br/>
1. Membership When you use this Site, you represent that: <br/>
(a) the information you submit is truthful and accurate; <br/>
(b) you will update your contact information if it changes so that we can contact you; <br/>
(c) your use of this Site and your use of services available on this Site do not violate any applicable law or regulation; <br/>
(d) you are 13 years of age or older; and <br/>
(e) you will comply with the rules for on-line conduct and making Contributions (as defined in Section 2 below) to this Site, as discussed in Section 2 below. You further represent and warrant that you will comply with all local rules regarding on-line conduct and acceptable Contributions. 2. User conduct This Site may provide fora and other features for communication. Please read our Privacy Policy, available at https://hussklive.com/privacypolicy to understand your privacy protections. You are entirely responsible for the content of, and any harm resulting from, any of your postings or submissions to this Site (collectively, &quot;Contributions&quot;). 
< br/>
</p>

                      </div>
                
                  </div>
                  <div className="col-md-6">
                 
                  </div>
                </div>
              </div>
            </div>
          </section>


     </div>

      </div>
    );
  }
}
