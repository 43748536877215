
import './App.css';
import Home from './page/Home'
import Registration from './page/Registration'
import Dashboard from './page/Dashboard'
import Profile from './page/profile'
import Findmovie from './page/Findmovie'
import Editprofile from './page/editprofile'
import Myphotoupload from './page/myphotoupload'
import Viewinformation from './page/viewinformation'
import Aboutme from './page/aboutme'
import Changepassword from './page/changepassword'
import Showdetails from './page/Showdetails'
import Continuewithemail from './page/Continuewithemail'
import Moviedetails from './page/Moviedetails'
import Movies from './page/movies'
import Shows from './page/Shows'
import Drama from './page/Drama'
import Tv from './page/Tv'
import Login from './page/Login'
import Manageprofile from './page/manageprofile'
import Watchhistory from './page/watchhistory'
import Setting from './page/setting'
import Pricing from './page/pricing'
import Termsofuse from './page/Termsofuse'
import Privacypolicy from './page/Privacypolicy'
import Faq from './page/Faq'
import Watchvideo from './page/Watchvideo'
import Logintbygoogle from './page/Logintbygoogle'
import Ads from './page/Ads'
import Test from './page/Test'
import Subscription from './page/Subscription'
import Package from './page/Package'
import Moviedetailspage from './page/Moviedetailspage'
import Finddata from './page/Finddata'
import Packagedetails from './page/Packagedetails'
import Categoriesview from './page/Categoriesview'
import Googlelogin from './page/Googlelogin'
import Forgetpassword from './page/Forgetpassword'
import Unsubscribe from './page/Unsubscribe'
import Homepage from './page/Homepage'
import Viewmore from './page/Viewmore'
import Movieplay from './page/Movieplay'

// import ReactJWPlayerContainer from './page/ReactJWPlayerContainer'
//import Testpage from './page/Testpage'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

function App() {
    return (

        <Router>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/registration">
                    <Registration />
                </Route>
                <Route exact path="/Finddata">
                    <Finddata />
                </Route>
                <Route exact path="/Categoriesview">
                    <Categoriesview />
                </Route>
                <Route exact path="/Googlelogin">
                    <Googlelogin />
                </Route>
                
                <Route exact path="/Forgetpassword">
                    <Forgetpassword />
                </Route>
               

                <Route exact path="/Test">
                    <Test />
                </Route>
                <Route exact path="/Package">
                    <Package />
                </Route>

                <Route exact path="/Packagedetails">
                    <Packagedetails />
                </Route>

                <Route exact path="/Watchvideo">
                    <Watchvideo />
                </Route>
                <Route exact path="/Logintbygoogle">
                    <Logintbygoogle />
                </Route>
                <Route exact path="/Ads">
                    <Ads />
                </Route>


                <Route exact path="/Setting">
                    <Setting />
                </Route>

                <Route exact path="/Showdetails">
                    <Showdetails />
                </Route>
                <Route exact path="/Dashboard">
                    <Dashboard />
                </Route>
                <Route exact path="/Profile">
                    <Profile />
                </Route>
                <Route exact path="/Privacypolicy">
                    <Privacypolicy />
                </Route>
                <Route exact path="/Faq">
                    <Faq />
                </Route>



                <Route exact path="/Pricing">
                    <Pricing />
                </Route>

                <Route exact path="/Subscription">
                    <Subscription />
                </Route>

                <Route exact path="/Termsofuse">
                    <Termsofuse />
                </Route>
                {/*       <Route exact path="/ReactJWPlayerContainer">
          <ReactJWPlayerContainer />
      </Route>
 */}




                <Route exact path="/Movies">
                    <Movies />
                </Route>

                <Route exact path="/Shows">
                    <Shows />
                </Route>
                <Route exact path="/Drama">
                    <Drama />
                </Route>

                <Route exact path="/Tv">
                    <Tv />
                </Route>


                <Route exact path="/Continuewithemail">
                    <Continuewithemail />
                </Route>
                <Route exact path="/Homepage/:id" component={Homepage}>
                    {/*  <Add_new_product_edit/> */}
                </Route>
                <Route exact path="/Viewmore/:id" component={Viewmore}>
                    {/*  <Add_new_product_edit/> */}
                </Route>
                

                <Route exact path="/Moviedetails/:id/:categoryid/:categoryname" component={Moviedetails}>
                    {/*  <Add_new_product_edit/> */}
                </Route>

                <Route exact path="/Movieplay/:id/:categoryid" component={Movieplay}>
                    {/*  <Add_new_product_edit/> */}
                </Route>


                


                <Route exact path="/Unsubscribe/:id" component={Unsubscribe}>
                    {/*  <Add_new_product_edit/> */}
                </Route>


                <Route exact path="/Moviedetailspage/:id/:categoryid/:categoryname" component={Moviedetailspage}>
                    {/*  <Add_new_product_edit/> */}
                </Route>

                <Route exact path="/Findmovie/:id" component={Findmovie}>

                </Route>


                <Route exact path="/Login">
                    <Login />
                </Route>

                <Route exact path="/Editprofile">
                    <Editprofile />
                </Route>
                <Route exact path="/myphotoupload">
                    <Myphotoupload />
                </Route>
                <Route exact path="/Manageprofile">
                    <Manageprofile />
                </Route>
                <Route exact path="/Watchhistory">
                    <Watchhistory />
                </Route>


                <Route exact path="/Changepassword">
                    <Changepassword />
                </Route>


                <Route exact path="/Viewinformation/:id/:firstname/:lastname" component={Viewinformation}>
                    {/*  <Add_new_product_edit/> */}
                </Route>

            </Switch>
        </Router>
    );
}

export default App;
