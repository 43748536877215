import React, { Component } from 'react'
import { Link } from 'react-router-dom';
//import { Redirect } from 'react-router';
//import * as base from "../page/global";
//import axios from 'axios';


export default class Finddata extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: 'Please write an essay about your favorite DOM element.'
      };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit(event) {
      alert('An essay was submitted: ' + this.state.value);
      event.preventDefault();
    }
  
    render() {
      return (
        <form onSubmit={this.handleSubmit}>
          <label>
            Essay:
          {/*   <textarea value={this.state.value} onChange={this.handleChange} /> */}
            <input type="text" name="txtsearch" id="txtsearch"  value={this.state.value} onChange={this.handleChange} className="text search-input font-size-12" />
          </label>
          <input type="submit" value="Submit" />
        </form>
      );
    }
  }