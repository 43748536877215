import React, { useEffect, useState } from 'react';
//import { Redirect } from 'react-router';
import Header from '../Layout/Header'
import { Redirect } from 'react-router';
import * as base from "./global";
import { Link } from 'react-router-dom';
import axios from 'axios';
//import { Button, Modal } from 'react-bootstrap';
import Sliderpage from './slider/sliderpage'
import Dramaseries from './drama/dramaseries'
import Footer from '../Layout/Footer'
//pagination npm install react-js-pagination
import Pagination from "react-js-pagination";


function Viewmore(props) {
    const [state, setData] = useState({users: ''});
    const [categoryid, setCategoryid] = useState(props.match.params.id);

    const fetchData = async (pageNumber = 1) => {
        //const api = await fetch(base.BASE_URL + `/allmovielist/${txtsearch}?page=${pageNumber}`);
        const api = await fetch(base.BASE_URL + `/Viewmore/${categoryid}?page=${pageNumber}`);
        setData({
            users: await api.json()
        });
    };

    useEffect(() => {
        fetchData();
    }, [])



    return (
        <div>
            <Header />


            <section id="iq-favorites" style={{ marginTop: 0 }}>
                <div className="container-fluid">
                    <div className="block-space">
                        <div style={{ marginTop: 40 }}>
                            <div style={{ display: 'flex',   flexDirection: "row",
    flexWrap: "wrap",  }}>
                                {
                                    state?.users?.data ?
                                        state?.users?.data?.map((result) => (
                                               /*  <p> {result.moviename} </p>     */
                                            <div style={{ width: 250, padding: 10 }}>
                                                <Link to={`/moviedetails/${result.movieid}/${result.categoryid}/${result.moviename}`} className='bg-image hover-zoom'>
                                                    <img src={`${base.IMAGE_URL}/${result.image}`} className="img-fluid img-zoom"
                                                        alt={result.moviename} style={{ height: '100%', width: '100%' }} />
                                                </Link>
                                            </div> 

                                        )) : "Loading..."
                                }

                            </div>
                        </div>
                    </div>
{/*  */}

<div style={{ width: '100%', marginTop: 20 }}>
<Pagination
activePage={state?.users?.current_page ? state?.users?.current_page : 0}
itemsCountPerPage={state?.users?.per_page ? state?.users?.per_page : 0 }
totalItemsCount={state?.users?.total ? state?.users?.total : 0}
onChange={(pageNumber) => {
fetchData(pageNumber)
}}
pageRangeDisplayed={8}
itemClass="page-item"
linkClass="page-link"
//firstPageText="First Page"
//lastPageText="Last Lage"
/>

</div>
{/*  */}


                </div>
            </section>


            <Footer />
        </div>
    );

}
export default Viewmore;
