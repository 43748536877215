import React, { Component } from 'react'
import { Link, Redirect  } from 'react-router-dom';
//import { Redirect } from 'react-router';
import * as base from "../page/global";
import axios from 'axios';
//import { Button, Form } from 'reactstrap';


import Finddata from '../page/Finddata';


export default class Header extends Component {
  //export class Header extends Component {
  constructor(props) {
    super(props);
   
    this.handlefindtxtsearchChange = this.handlefindtxtsearchChange.bind(this);
    this.finddata = this.finddata.bind(this);
    this.state = {
      emailaddress: localStorage.getItem("emailaddress"),
      datayourmatch: [],
      datauserprofile: [],
      image: 'maleavter.png',
      txtsearch: '',
      txtglobelsearch: 'No'
    }
  }
  logout = () => {
    window.localStorage.clear();
    window.location.href = "/";
  }



  handlefindtxtsearchChange(event) {
    this.setState({txtsearch: event.target.value});
  }

  finddata(event) {
   // alert('An essay was submitted: ' + this.state.txtsearch);
    event.preventDefault();
      this.setState({
        txtglobelsearch: 'Yes'
      })
  }


  componentDidMount() {

    axios.get(base.BASE_URL + '/viewprofile/' + this.state.emailaddress).then(res => {
      // alert();
      
     // alert(res.data[0].gender);
      this.setState({
        datauserprofile: res.data,
        name: res.data[0].name,
        dob: res.data[0].dob,
        gender: res.data[0].gender,
        language: res.data[0].language,
      });
      console.log(res.data)
    });

  }



  render() {
    /*     if (this.state.emailaddress == "") {
          return <Redirect to="/" />
        } */

        if (this.state.txtglobelsearch == "Yes") {
          const url = this.state.txtsearch;
          //return <Redirect to='/Findmovie' data={this.state.txtsearch} />
          return <Redirect to={`/Findmovie/${url}`} />
        }
    return (
      <div>

        <header id="main-header">
          <div className="main-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <a href="#" className="navbar-toggler c-toggler" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <div className="navbar-toggler-icon" data-toggle="collapse">
                        <span className="navbar-menu-icon navbar-menu-icon--top" />
                        <span className="navbar-menu-icon navbar-menu-icon--middle" />
                        <span className="navbar-menu-icon navbar-menu-icon--bottom" />
                      </div>
                    </a>
                    <Link className="navbar-brand" to="/">
                      <img className="img-fluid logo" src="/images/logo.png" alt="Hussk Live" />
                    </Link>
                   
                    <div className="mobile-more-menu">
                      <a href="javascript:void(0);" className="more-toggle" id="dropdownMenuButton" data-toggle="more-toggle" aria-haspopup="true" aria-expanded="false">
                        <i className="ri-more-line" />
                      </a>
                      <div className="more-menu" aria-labelledby="dropdownMenuButton">
                        <div className="navbar-right position-relative">
                          <ul className="d-flex align-items-center justify-content-end list-inline m-0">
                          {/*   <li> */}
                            {/*   <a href="#" className="search-toggle">
                                <i className="ri-search-line" />
                              </a> */}
                            {/*    <div className="search-box iq-search-bar">  */}
                         
                              {/*  <Finddata />  */}

                              {/*                                 
                              <form onSubmit={this.finddata}>
                                <div action="#" className="searchbox">
                                  <div className="form-group position-relative">
                                    <input type="text" name="txtsearch" id="txtsearch"  value={this.state.txtsearch} onChange={this.handlefindtxtsearchChange} className="text search-input font-size-12" />
                                  
                                    <i className="search-link ri-search-line" />
                                  </div>
                                </div>
                                </form> 
                                 
                                 
                                 */}
                              




                           {/*   </div>  
                            </li> */}
                            <li className="nav-item nav-icon">
                              <a href="#" className="search-toggle position-relative">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={22} height={22} className="noti-svg">
                                  <path fill="none" d="M0 0h24v24H0z" />
                                  <path d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z" />
                                </svg>
                                <span className="bg-danger dots" />
                              </a>
                              <div className="iq-sub-dropdown">
                                <div className="iq-card shadow-none m-0">
                                  <div className="iq-card-body">
                                    <a href="#" className="iq-sub-card">
                                      <div className="media align-items-center">
                                        <img src="/images/notify/thumb-1.jpg" className="img-fluid mr-3" alt="streamit" />
                                        <div className="media-body">
                                          <h6 className="mb-0 ">Boop Bitty</h6>
                                          <small className="font-size-12"> just now</small>
                                        </div>
                                      </div>
                                    </a>

                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <a href="#" className="iq-user-dropdown search-toggle d-flex align-items-center">
                                <img src="/images/user/user.jpg" className="img-fluid avatar-40 rounded-circle" alt="user" />
                              </a>
                              <div className="iq-sub-dropdown iq-user-dropdown">
                                <div className="iq-card shadow-none m-0">
                                  <div className="iq-card-body p-0 pl-3 pr-3">
                                    <Link to="/Manageprofile" className="iq-sub-card setting-dropdown">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-file-user-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="mb-0 ">Manage Profile</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link href="/Watchhistory" className="iq-sub-card setting-dropdown">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-settings-4-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="mb-0 ">Watch History</h6>
                                        </div>
                                      </div>
                                    </Link>

                                    <Link to="/setting" className="iq-sub-card setting-dropdown">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-settings-4-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="mb-0 ">Settings</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="/Pricing" className="iq-sub-card setting-dropdown">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-settings-4-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="mb-0 " style={{ color: '#BB2404' }}>Subscribes</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="#" className="iq-sub-card setting-dropdown" onClick={this.logout}>
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-logout-circle-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="mb-0">Logout</h6>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <div className="menu-main-menu-container">
                        <ul id="top-menu" className="navbar-nav ml-auto">
                          <li className="menu-item">
                            <Link to="/">Home</Link>
                          </li>
{/*                           <li className="menu-item">
                            <Link to="/Movies">Movies</Link>
                          </li> */}
                          <li className="menu-item">
                            <Link to="/Shows">Shows</Link>
                          </li>
                          {/*                   <li className="menu-item">
                    <Link to="/drama">Drama</Link>
                    <ul className="sub-menu">
                      <li className="menu-item"><Link to="blog.html">Blog</Link></li>
                      <li className="menu-item"><Link to="blog-details.html">Blog details</Link></li>
                    </ul>
                  </li>   */}
                          <li className="menu-item">
                            <Link to="/Drama">Drama</Link>
                          </li>
                          <li className="menu-item">
                            <Link to="/Tv">TV</Link>
                          </li>
                          <li className="menu-item">
                            <Link to="/Subscription">Subscribes</Link>
                          </li>

                          {/*                   <li className="menu-item">
                    <Link to="#">TV</Link>
                    <ul className="sub-menu">
                      <li className="menu-item"><Link to="blog.html">Blog</Link></li>
                      <li className="menu-item"><Link to="blog-details.html">Blog details</Link></li>
                    </ul>
                  </li>    */}
                          {/*                   <li className="menu-item">
                    <a href="#">Pages</a>
                    <i className="ion-ios-arrow-down toggledrop" aria-hidden="true" />
                    <ul className="sub-menu">
                      <li className="menu-item"><a href="about-us.html">About Us</a></li>
                      <li className="menu-item "><a href="contact.html">Contact</a></li>
                      <li className="menu-item"><a href="faq.html">FAQ</a></li>
                      <li className="menu-item"><a href="privacy-policy.html">Privacy-Policy</a></li>
                      <li className="menu-item"><a href="#">Pricing</a>
                        <ul className="sub-menu">
                          <li className="menu-item"><a href="pricing-plan-1.html">Pricing Plan 1</a></li>
                          <li className="menu-item"><a href="pricing-plan-2.html">Pricing Plan 2</a></li>
                        </ul>
                      </li>
                    </ul>
                  </li> */}
                          {
                            this.state.emailaddress == null
                              ?
                              <li className="menu-item">
                                <Link to="/Registration">Registration/Login</Link>
                              </li>
                              :
                              ''
                          }

                        </ul>
                      </div>
                    </div>
                    <div className="navbar-right menu-right">
                      <ul className="d-flex align-items-center list-inline m-0">
                        <li className="nav-item nav-icon">
                          <a href="#" className="search-toggle device-search">
                            <i className="ri-search-line" />
                          </a>
                          <div className="search-box iq-search-bar d-search">

                            {/* destop search bar */}
{/*                             <Form onSubmit={this.finddata}>
                              <div action="#" className="searchbox">
                                <div className="form-group position-relative">
                                  <input type="text" className="text search-input font-size-12" placeholder="type here to search..." />
                                  <i className="search-link ri-search-line" />
                                </div>
                              </div>
                            </Form>+ */}
                            <form onSubmit={this.finddata}>
                                <div action="#" className="searchbox">
                                  <div className="form-group position-relative">
                                    <input type="text" name="txtsearch" id="txtsearch"  value={this.state.txtsearch} placeholder="search by name"
                                    onChange={this.handlefindtxtsearchChange} className="text search-input font-size-12" required />
                                  
                                    <i className="search-link ri-search-line" />
                                  </div>
                                </div>
                             </form> 


                          </div>
                        </li>
                        <li className="nav-item nav-icon">
                          <a href="#" className="search-toggle" data-toggle="search-toggle">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={22} height={22} className="noti-svg">
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z" />
                            </svg>
                            <span className="bg-danger dots" />
                          </a>
                          <div className="iq-sub-dropdown">
                            <div className="iq-card shadow-none m-0">
                              <div className="iq-card-body">
{/*                                 <a href="#" className="iq-sub-card">
                                  <div className="media align-items-center">
                                    <img src="images/notify/thumb-1.jpg" className="img-fluid mr-3" alt="streamit" />
                                    <div className="media-body">
                                      <h6 className="mb-0 ">Boot Bitty</h6>
                                      <small className="font-size-12"> just now</small>
                                    </div>
                                  </div>
                                </a>
                                <a href="#" className="iq-sub-card">
                                  <div className="media align-items-center">
                                    <img src="images/notify/thumb-2.jpg" className="img-fluid mr-3" alt="streamit" />
                                    <div className="media-body">
                                      <h6 className="mb-0 ">The Last Breath</h6>
                                      <small className="font-size-12">15 minutes ago</small>
                                    </div>
                                  </div>
                                </a>
                                <a href="#" className="iq-sub-card">
                                  <div className="media align-items-center">
                                    <img src="images/notify/thumb-3.jpg" className="img-fluid mr-3" alt="streamit" />
                                    <div className="media-body">
                                      <h6 className="mb-0 ">The Hero Camp</h6>
                                      <small className="font-size-12">1 hour ago</small>
                                    </div>
                                  </div>
                                </a> */}
                              </div>
                            </div>
                          </div>
                        </li>

                        {
                          this.state.emailaddress == null
                            ?
                            ''
                            :
                            <li className="nav-item nav-icon">
                              <a href="#" className="iq-user-dropdown search-toggle p-0 d-flex align-items-center" data-toggle="search-toggle">
                                {
                                  this.state.gender == 'Male'
                                  ?
                                  <img src="/images/user/user.jpg" className="img-fluid avatar-40 rounded-circle" alt="user" />
                                 
                                  :
                                  <img src="/images/user/women.jpg" className="img-fluid avatar-40 rounded-circle" alt="user" />
                                }
                               
                              </a>


                              <div className="iq-sub-dropdown iq-user-dropdown">
                                <div className="iq-card shadow-none m-0">
                                  <div className="iq-card-body p-0 pl-3 pr-3">
                                    <Link to="/Manageprofile" className="iq-sub-card setting-dropdown">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-file-user-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">My Account</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="/Matchhistoryview" className="iq-sub-card setting-dropdown">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-settings-4-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">Watch History</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="/setting" className="iq-sub-card setting-dropdown">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-settings-4-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">Settings</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="/Pricing" className="iq-sub-card setting-dropdown">
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-settings-4-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">Pricing Plan</h6>
                                        </div>
                                      </div>
                                    </Link>
                                    <Link to="#" className="iq-sub-card setting-dropdown" onClick={this.logout}>
                                      <div className="media align-items-center">
                                        <div className="right-icon">
                                          <i className="ri-logout-circle-line text-primary" />
                                        </div>
                                        <div className="media-body ml-3">
                                          <h6 className="my-0 ">Logout</h6>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </li>

                        }




                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>





      </ div>
    )
  }
}
