import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class Footer extends Component {
  constructor() {
    super();

  }
  render() {

    return (
<footer id="contact" className="footer-one iq-bg-dark">
  <div className="footer-top">
    <div className="container-fluid">
      <div className="row footer-standard">
        <div className="col-lg-7">
          <div className="widget text-left">
            <div className="menu-footer-link-1-container">
              <ul id="menu-footer-link-1" className="menu p-0">
                <ul id="menu-footer-link-1" className="menu p-0">
                  <li id="menu-item-7314" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314">
                    <Link to="/Termsofuse">Terms Of Use</Link>
                  </li>
                  <li id="menu-item-7316" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316">
                    <Link to="/privacypolicy">Privacy-Policy</Link>
                  </li>
                  <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118">
                    <Link to="/Faq">FAQ</Link>
                  </li>
                  <li id="menu-item-7118" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7118">
                    <Link to="/watchvideo">Watch List</Link>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <div className="widget text-left">			
            <div className="textwidget">
              <p><small>© 2021 Hussk Production. All Rights Reserved.</small></p>
            </div>
          </div>                        
        </div>
        <div className="col-lg-2 col-md-6 mt-4 mt-lg-0">
          <h6 className="footer-link-title">
            Follow Us :
          </h6>
          <ul className="info-share"> 
            <li><a href="https://www.facebook.com/profile.php?id=100074214505261" target="_blank"><i className="fa fa-facebook" /></a></li>
            <li><a href="" target="_blank"><i className="fa fa-twitter" /></a></li>
            <li><a href="" target="_blank"><i className="fa fa-google-plus" /></a></li>
            <li><a href="" target="_blank"><i className="fa fa-github" /></a></li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
          <div className="widget text-left">
            <div className="textwidget">
              <h6 className="footer-link-title">Hussk Production</h6>
              <div className="d-flex align-items-center">
                <a className="app-image" href="#">
                  <img src="/images/footer/01.jpg" alt="play-store" />
                </a><br />
              {/*   <a className="ml-3 app-image" href="#"><img src="/images/footer/02.jpg" alt="app-store" /></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
    )
}
}

export default Footer